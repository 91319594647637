<template>
    <div class="auth-layout" :class="{ 'prepare-account-layout': $route.name==='auth.setup-account'}">
        <div class="auth-form-card">
            <auth-card-left/>

            <div class="auth-form-right">
                <transition name="auth-slide" mode="out-in">
                    <router-view/>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import AuthCardLeft from "@/layouts/components/auth/AuthCardLeft";

export default {
    components: {
        AuthCardLeft
    }
}
</script>

<style>
@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(200px);
        transform: translateX(200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(200px);
        transform: translateX(200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-200px);
        transform: translateX(-200px);
        opacity: 0;
    }
}

@keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-200px);
        transform: translateX(-200px);
        opacity: 0;
    }
}

.auth-slide-enter-active {
    -webkit-animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.auth-slide-leave-active {
    -webkit-animation: slide-out-left 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-left 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
</style>
